import { render, staticRenderFns } from "./CreateSingle.vue?vue&type=template&id=00277a76&scoped=true"
import script from "./CreateSingle.vue?vue&type=script&lang=js"
export * from "./CreateSingle.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00277a76",
  null
  
)

export default component.exports